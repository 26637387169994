import { render, staticRenderFns } from "./PBGPowerBall5M.vue?vue&type=template&id=16f0b98d&scoped=true&"
import script from "./PBGPowerBall5M.vue?vue&type=script&lang=js&"
export * from "./PBGPowerBall5M.vue?vue&type=script&lang=js&"
import style0 from "./PBGPowerBall5M.vue?vue&type=style&index=0&id=16f0b98d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16f0b98d",
  null
  
)

export default component.exports